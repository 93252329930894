import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";

import { clearMessage } from "../../../redux/actions/message";

import SuperAdminCheck from "../../../routes/SuperAdminCheck";
import ProtectedRoutes from "../../../routes/ProtectedRoutes";
import PublicRoutes from "../../../routes/PublicRoutes";
import { useHideUnimportantErrors } from "../../../hooks/useHideUnimportantErrors.js";

import AddFaq from "../../FAQ/AddFaq";
import EditFaq from "../../FAQ/EditFaq";
import FaqList from "../../FAQ/All";
import BannerList from "../../Banner/All";
import EditBanner from "../../Banner/EditBanner";
import AddBanner from "../../Banner/AddBanner";

const Login = lazy(() => import("../../Login/"));
const Dashboard = lazy(() => import("../../Dashboard/"));
const ForgotPassword = lazy(() => import("../../ForgotPassword/"));
const ResetPassword = lazy(() => import("../../ResetPassword/"));

const UserList = lazy(() => import("../../Users/All"));
const ReportedUsersList = lazy(() => import("../../Users/ReportedUsers"))
const ReportedUserInformation = lazy(() => import("../../Users/ReportedUserView"))
const ActiveList = lazy(() => import("../../Users/ActiveListing"));
const UserTransaction = lazy(() => import("../../Users/userTransaction/UserTransaction "));
const AddUser = lazy(() => import("../../Users/AddUser"));
const User = lazy(() => import("../../Users/User"));
// Users Self Information
const EditUser = lazy(() => import("../../Users/EditUser"));

const SellerList = lazy(() => import("../../Seller/All"));
const Seller = lazy(() => import("../../Seller/Seller"));
const EditSeller = lazy(() => import("../../Seller/EditSeller"))

const SellList = lazy(() => import("../../Orders/SellList"));
const Purchaselist = lazy(() => import("../../Orders/Purchaselist"));
const Tradelist = lazy(() => import("../../Orders/Trade&Play/TradeList"));
const TradeInformation = lazy(() => import("../../Orders/Trade&Play/TradeInformation"));
const PurchaseInformation = lazy(() => import("../../Orders/PurchaseInformation"));
const SellInformation = lazy(() => import("../../Orders/SellInformation"));

const GamesList = lazy(() => import("../../Games/All"));
const AddGame = lazy(() => import("../../Games/AddGames"));
const GamesInformation = lazy(() => import("../../Games/SeeGamesInformation"));
const SoldOrderList = lazy(() => import("../../Games/Sold-OrdersList"));

const DisputeList = lazy(() => import("../../Dispute/All"));
const AddDispute = lazy(() => import("../../Dispute/Add"));
const DisputeView = lazy(() => import("../../Dispute/DisputeView"));

const AddRole = lazy(() => import("../../Role/AddRole"));
const Permissions = lazy(() => import("../../Role/All"));
const ViewSingalRole = lazy(() => import("../../Role/ViewSingal"));

const AddRules = lazy(() => import("./../../RulesAndRegulations/AddRules"));
const RulesList = lazy(() => import("./../../RulesAndRegulations/All"));
const EditRules = lazy(() => import("./../../RulesAndRegulations/EditRules"));

const AddInsurance = lazy(() => import("../../InsurancePlan/AddInsurance"));
const InsuranceList = lazy(() => import("../../InsurancePlan/All"));
const EditInsurance = lazy(() => import("../../InsurancePlan/EditInsurance"));

const AddCms = lazy(() => import("../../CMS/AddCms"));
const CmsList = lazy(() => import("../../CMS/All"));
const EditCms = lazy(() => import("../../CMS/EditCms"));

const AddBooster = lazy(() => import("./../../BoosterPlan/AddBooster"));
const BoosterList = lazy(() => import("./../../BoosterPlan/All"));
const EditBooster = lazy(() => import("./../../BoosterPlan/EditBooster"));

const ContactList = lazy(() => import("../../Contacts/All"));
const Contact = lazy(() => import("../../Contacts/Contact"));

// const ReturnList = lazy(() => import("../../Returns/All"));
// const Return = lazy(() => import("../../Returns/Return"));

const ServiceChargeList = lazy(() => import("../../ServiceCharge/All"));
const AddCharge = lazy(() => import("../../ServiceCharge/AddCharge"));

const PageNotFound = lazy(() => import("../../PageNotFound"));
const ForbiddenPage = lazy(() => import("../../Forbidden"))
const ChatList = lazy(() => import("../../Chat/List"))

const TransactionList = lazy(() => import("../../Transactions/All"));
const TransactionView = lazy(() => import("../../Transactions/TransactionView"));
const RefundList = lazy(() => import("../../Transactions/RefundList"));

const AppMain = () => {
  let { isLoggedIn, user: currentUser, permissionMap } = useSelector(
    (state) => state.auth
  );
  const { permission } = permissionMap || {};

  const dispatch = useDispatch();

  let location = useLocation();

  useEffect(() => {
    if (["/login"].includes(location.pathname)) {
      dispatch(clearMessage()); // clear message when changing location
    }

  }, [dispatch, location]);

  useHideUnimportantErrors()
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route element={<ProtectedRoutes isLoggedIn={isLoggedIn} />}>
          <Route path="dashboard" element={<Dashboard permission={permission} />} />
          <Route path="profile" element={<EditUser currentUser={currentUser} />} />
          <Route path="user">
            <Route path="list" element={<UserList currentUser={currentUser} />} />
            <Route path="reported/list" element={<ReportedUsersList currentUser={currentUser} />} />
            <Route path="reported/:id" element={<ReportedUserInformation currentUser={currentUser} />} />
            <Route path="ad/:id" element={<ActiveList currentUser={currentUser} />} />
            <Route path="transaction/:id" element={<UserTransaction currentUser={currentUser} />} />
            <Route path="add" element={<AddUser user={permission} />} />
            <Route path=":id" element={<User currentUser={currentUser} />} />
          </Route>
          <Route path="seller">
            <Route
              path="list"
              element={<SellerList currentUser={currentUser} />}
            />
            <Route path="edit/:id" element={<EditSeller currentUser={currentUser} />} />
            <Route path=":id" element={<Seller currentUser={currentUser} />} />
          </Route>
          <Route path="order">
            <Route path="selllist" element={<SellList currentUser={currentUser} />} />
            <Route path="selllist/:id" element={<SellInformation />} />
            <Route path="tradelist" element={<Tradelist currentUser={currentUser} />} />
            <Route path="tradelist/:id" element={<TradeInformation currentUser={currentUser} />} />
            <Route path="purchaselist" element={<Purchaselist currentUser={currentUser} />} />
            <Route path="purchaselist/:id" element={<PurchaseInformation />} />
          </Route>
          <Route path="games">
            <Route path="add" element={<AddGame currentUser={currentUser} />} />
            <Route path="list" element={<GamesList currentUser={currentUser} />} />
            <Route path=":id" element={<GamesInformation currentUser={currentUser} />} />
            <Route path="sold-orders/:id" element={<SoldOrderList currentUser={currentUser} />} />
          </Route>
          <Route path="dispute">
            <Route path="list" element={<DisputeList currentUser={currentUser} />} />
            <Route path="add" element={<AddDispute currentUser={currentUser} />} />
            <Route path=":id" element={<DisputeView permission={permission} />} />
          </Route>

          <Route path="role" element={<SuperAdminCheck permission={permission} />}>
            <Route path="list" element={<AddRole currentUser={currentUser} />} />
            <Route path="permission" element={<Permissions />} />
            <Route path=":id" element={<ViewSingalRole />} />
          </Route>
          <Route path="faq">
            <Route path="list" element={<FaqList currentUser={currentUser} />} />
            <Route path="add" element={<AddFaq currentUser={currentUser} />} />
            <Route path=":id" element={<EditFaq currentUser={currentUser} />} />
          </Route>
          <Route path="banner">
            <Route path="list" element={<BannerList currentUser={currentUser} />} />
            <Route path="add" element={<AddBanner currentUser={currentUser} />} />
            <Route path=":id" element={<EditBanner currentUser={currentUser} />} />
          </Route>

          <Route path="rules">
            <Route path="list" element={<RulesList currentUser={currentUser} />} />
            <Route path="add" element={<AddRules currentUser={currentUser} />} />
            <Route path=":id" element={<EditRules currentUser={currentUser} />} />
          </Route>

          <Route path="insurance">
            <Route path="list" element={<InsuranceList currentUser={currentUser} />} />
            <Route path="add" element={<AddInsurance currentUser={currentUser} />} />
            <Route path=":id" element={<EditInsurance currentUser={currentUser} />} />
          </Route>

          <Route path="cms">
            <Route path="list" element={<CmsList currentUser={currentUser} />} />
            <Route path="add" element={<AddCms currentUser={currentUser} />} />
            <Route path=":id" element={<EditCms currentUser={currentUser} />} />
          </Route>

          <Route path="booster">
            <Route path="list" element={<BoosterList currentUser={currentUser} />} />
            <Route path="add" element={<AddBooster currentUser={currentUser} />} />
            <Route path=":id" element={<EditBooster currentUser={currentUser} />} />
          </Route>

          <Route path="support">
            <Route path="list" element={<ContactList currentUser={currentUser} />} />
            <Route path=":id" element={<Contact currentUser={currentUser} />} />
          </Route>

          <Route path="chat">
            <Route path="list" element={<ChatList currentUser={currentUser} />} />
            {/* <Route path=":id" element={<Return currentUser={currentUser} />} /> */}
          </Route>

          <Route path="charges">
            <Route path="list" element={<ServiceChargeList currentUser={currentUser} />} />
            <Route path="add" element={<AddCharge currentUser={currentUser} />} />
          </Route>

          <Route path="transaction">
            <Route path="list" element={<TransactionList />} />
            <Route path="refund/list" element={<RefundList />} />
            <Route path=":id" element={<TransactionView />} />
          </Route>

        </Route>
        <Route element={<PublicRoutes isLoggedIn={isLoggedIn} />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="/" element={<Login />} />
        </Route>

        <Route path="forbidden" element={<ForbiddenPage />} />
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </Suspense>
  );
};

export default AppMain;
